.main-navigation {
  position: static !important;
  background-color: #fff !important;
}
.logo {
  margin: 0.625rem 0;
  @media only screen and (max-width: 360px) {
    margin: 0.625rem 0.625rem 0.625rem 0;
  }
}
.nav-listing {
  button {
    font-size: 1.125rem;
    color: $theme-blue;
    text-transform: capitalize;
    padding: 0px;
    margin-right: 1.875rem;
  }
}

.text-note {
  background: rgb(33, 158, 188);
  background: linear-gradient(
    90deg,
    rgba(33, 158, 188, 1) 0%,
    rgba(15, 51, 109, 1) 50%,
    rgba(33, 158, 188, 1) 100%
  );
  p {
    color: $theme-white;
    font-family: "Hanuman", serif;
    font-size: 1.25rem;
    // font-weight: bold;
    line-height: 28px;
    padding: 14px 0 8px 0;
    text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);

    a {
      color: $theme-orange;
    }
  }
}

.fee-note {
  font-family: "Hanuman", serif;
  font-size: 1rem !important;
  line-height: 24px !important;
  padding: 14px 0 8px 0 !important;

  a {
    color: $theme-orange;
  }
}
