.w-100 {
  width: 100% !important;
}
.w-50 {
  width: 50% !important;
}

.modal-close-icon {
  position: absolute;
  top: 0.9375rem;
  right: 0.9375rem;
  cursor: pointer;
}
.cursor-pointer {
  cursor: pointer;
}
.check-box-control {
  span {
    font-family: "Lato", sans-serif !important;
  }
}
.radio-control {
  span {
    font-family: "Lato", sans-serif !important;
  }
}
.mx-10 {
  margin-right: 10em;
  margin-left: 10em;
}

.text-underline {
  text-decoration: underline;
}

.text-capitalize {
  text-transform:capitalize;
}