.banner {
  background: url("../../images/i2file-banner.jpg") no-repeat left top;
  width: 100%;
  background-size: cover;
  position: relative;
  &::before {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    content: "";
    background-color: rgba($color: #000000, $alpha: 0.5);
  }

  .banner-text {
    position: relative;
    z-index: 1;
    margin: 8rem 0 5.375rem 0;
    display: inline-block;
    width: 100%;

    h1 {
      color: $theme-white;
      font-size: 4.0625rem;
      font-family: "Hanuman", serif;
      font-weight: 900;
      display: inline-block;
      line-height: 85px;
      text-shadow: 0px 2px 0px #000000;
    }
    .banner-text-right {
      display: inline-block;
      position: relative;
      top: 35px;

      p {
        font-family: "Exo", sans-serif;
        font-size: 1.5625rem;
        color: $theme-white;
        position: relative;
        line-height: 45px;
        padding: 5px 0;
        text-shadow: 0px 2px 0px #000000;

        span {
          // font-weight: 800;
          background: $theme-grey;
          padding: 2px 5px;
          margin: 5px 0;
        }

        &::before {
          position: absolute;
          top: 0px;
          left: -30px;
          width: 5px;
          height: 100%;
          content: "";
          background-color: #8ecae6;
        }
      }

      .faq-btn {
        background-color: $theme-yellow;
        color: $theme-black !important;
      }
    }
  }
}
.banner-note {
  background-color: $lighter-blue;
  p {
    line-height: 2rem;
    font-size: 1.25rem;
    font-weight: 400;
    padding: 0.625rem 0;
  }
}

@media only screen and (max-width: 992px) {
  .banner .banner-text {
    margin: 4rem 0 5.375rem 0;
  }
  .banner .banner-text h1 {
    font-size: 3.0625rem;
    line-height: 4rem;
  }
  .banner .banner-text .banner-text-right p {
    font-size: 1.2rem;
    line-height: 1.8rem;
  }
  .banner-note p {
    line-height: 1.6rem;
    font-size: 1rem;
    padding: 0.225rem 0;
  }
  .text-note p {
    font-size: 1rem !important;
    line-height: 22px !important;
    padding: 5px 0 8px 0 !important;
  }
}

@media only screen and (max-width: 992px) {
  .banner .banner-text .banner-text-right {
    top: 5px;
  }
  .banner .banner-text {
    margin: 2rem 0 5.375rem 0;
  }
  .banner .banner-text h1 {
    font-size: 2.8rem;
    // line-height: 3.2rem;
  }
  .banner .banner-text .banner-text-right p {
    font-size: 1rem;
    // line-height: 1.6rem;
  }
  .banner-note p {
    line-height: 1.4rem;
    font-size: 0.9rem;
  }
}
