.pick-list {
  position: relative;
  .MuiCardHeader-root {
    color: $theme-dark-grey;
    font-weight: bold;
    padding-left: 0;
    padding-top: 0;
  }
  .MuiCard-root {
    box-shadow: none;
    background-color: transparent;
    overflow: initial;
    border-radius: 0;
    .MuiList-root {
      box-shadow: 0px 0.4px 3px 1px rgba(0, 0, 0, 0.25);
      background-color: $theme-white;
      border-radius: 4px;
      height: 320px;
      width: auto !important;
      .MuiListItemText-root {
        padding-left: 10px;
        padding-right: 10px;
      }
      .MuiTypography-root {
        color: $theme-black;
        word-break: break-all;
      }
    }
  }
  .pick-drop-button {
    &.left-button {
      @media only screen and (max-width: 767px) {
        margin-top: 20px;
      }
    }
    &.right-button {
      @media only screen and (max-width: 767px) {
        margin-bottom: 20px;
      }
    }
  }
}
