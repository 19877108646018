.container-scroller {
  overflow: hidden;
}
.brand-logo-mini {
  display: none;
}

.inner-comp {
  min-height: 100vh;
  font-size: 16px;
  box-sizing: border-box;
  position: relative;

  .mobile-menu-close {
    position: absolute;
    top: -0.4375rem;
    left: 319px;
    z-index: 1;
    display: none;
    svg {
      color: #d6f2ff;
      font-size: 3.125rem;
    }
  }

  &.overlay-col {
    &:after {
      @media only screen and (max-width: 1024px) {
        position: absolute;
        top: 0px;
        left: 0px;
        content: "";
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
      }
    }

    .mobile-menu-close {
      display: block;
    }
  }
}

.main_content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  width: calc(100% - 253px);
  margin-left: 253px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  min-height: calc(100vh - 60px);
  box-sizing: border-box;

  @media only screen and (max-width: 1024px) {
    width: 100%;
    margin-left: 0px;
  }

  &.toggle {
    width: calc(100% - 90px);
    margin-left: 90px;
  }

  .main_content-top {
    background-color: $lighter-blue;
    // padding-bottom: 1.25rem;
    padding-bottom: 10px;
    border-radius: 0 0 2.5rem 0;

    .toggle-col {
      width: 1.625rem;
      vertical-align: top;
      display: inline-block;
      padding: 1.6875rem 0 0 0;
      margin-right: 1.25rem;

      @media only screen and (max-width: 1024px) {
        padding: 1.6875rem 0 0 1.25rem;
      }

      .toggle-bar {
        cursor: pointer;
        &.toggle-bar-right {
          display: none !important;
          align-self: center;
        }
        &.toggle-bar-left {
          align-self: center;
        }
      }
    }

    .header-newsite-msg {
      padding: 10px 0 10px 84px;
      color: #c05360;

      @media only screen and (max-width: 1200px) {
        padding: 10px 0 10px 20px;
      }
    }

    .main_content-top-note {
      padding: 0 0 0 5.3125rem;
      align-self: center;
      display: flex;
      align-items: center;

      .i2file_request_link {
        background-color: $theme-blue;
        width: 30px;
        height: 30px;
        border-radius: 100px;
        color: #fff;
        font-size: 1rem;
        line-height: 30px;
        text-align: center;
        -webkit-animation: fa-spin 2s infinite linear;
        animation: fa-spin 2s infinite linear;
      }

      @media only screen and (max-width: 1024px) {
        padding: 1.6875rem 0 0 1.25rem;
      }

      p {
        font-weight: 400;
        color: $theme-grey;
        font-size: 1.0625rem;
        a {
          font-weight: 500;
          text-decoration: underline;
          color: $theme-grey;
        }
      }
      @media only screen and (max-width: 1200px) {
        padding: 0 0 0 20px;
        p {
          font-size: 14px;
        }
      }
    }
    .profile-right {
      display: inline-block;
      //float: right;
      padding-top: 0.875rem;
      margin-right: 1.4375rem;
      margin-left: auto;
      .profile-photo {
        display: flex;
        align-items: center;
        img {
          width: 2.5rem;
          height: 2.5rem;
          border-radius: 50%;
        }
        .profile-downarrow {
          // position: absolute;
          // top: 4px;
          // right: -35px;
          font-size: 30px;
          color: $theme-grey;
        }
      }

      .nav-profile {
        position: relative;

        .profile-options {
          position: absolute;
          top: 100%;
          right: 0;
          min-width: 225px;
          background: #fff;
          box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
            0px 4px 5px 0px rgba(0, 0, 0, 0.14),
            0px 1px 10px 0px rgba(0, 0, 0, 0.12);
          padding: 10px 20px;
          box-sizing: border-box;
          z-index: 11;
          border-radius: 10px;
          // display: none;
          li {
            line-height: 32px;
            font-size: 16px;

            a {
              color: $theme-blue;
              display: block;
            }

            &:hover {
              a {
                span {
                  text-decoration: underline;
                }
              }
            }
          }
        }
      }
    }
  }

  &.toggle {
    width: calc(100% - 90px);
    margin-left: 90px;

    @media only screen and (max-width: 1024px) {
      width: 100%;
      margin-left: 0;
    }

    .toggle-col {
      .toggle-bar {
        &.toggle-bar-left {
          display: none !important;
        }

        &.toggle-bar-right {
          display: inline-block !important;
        }
      }
    }
  }
  .p-input-icon-right {
    position: relative;

    .case-search {
      position: absolute;
      top: 5px;
      right: 0;
      font-size: 1.125rem;
      color: $mid-dark-grey;
    }
  }

  .card-sec {
    margin-top: 2.5rem;

    .border-left-col {
      border-left: 1px solid $theme-mid-grey;
    }
    .left-card-box {
      padding-right: 4rem;
    }
    .right-card-box {
      padding-left: 4rem;
    }

    .option-text {
      font-size: 1.125rem;
      line-height: 1.375rem;
      margin-bottom: 1.75rem;
      display: block;
      margin-top: 0.3125rem;
    }
    .box-card {
      box-shadow: none;
      border-radius: 0.625rem;
      padding: 3rem 1rem 0.5625rem 1rem;
      &.new-case-card {
        position: relative;
        &::before {
          position: absolute;
          top: -0.3125rem;
          right: 0.625rem;
          width: 3.625rem;
          height: 5.375rem;
          content: "";
          background: url("../../images/new-case-icon.svg") no-repeat left top;
        }
      }
      &.existing-case-card {
        position: relative;
        &::before {
          position: absolute;
          top: -0.3125rem;
          right: 0;
          width: 4.625rem;
          height: 5.375rem;
          content: "";
          background: url("../../images/existing-case.svg") no-repeat left top;
        }
      }
      &.drafts-card {
        position: relative;
        h4 {
          &.box-card-title {
            color: $theme-grey !important;
          }
        }
        &::before {
          position: absolute;
          top: -0.5rem;
          left: 0;
          width: 6.4375rem;
          height: 6.5rem;
          content: "";
          background: url("../../images/draft-icon.svg") no-repeat left top;
          background-size: 100% auto;
        }
      }
      &.pending-card {
        position: relative;
        h4 {
          &.box-card-title {
            color: $theme-grey !important;
          }
        }
        &::before {
          position: absolute;
          top: -0.5rem;
          left: 0;
          width: 5.6875rem;
          height: 6.5rem;
          content: "";
          background: url("../../images/pending-icon.svg") no-repeat left top;
          background-size: 100% auto;
        }
      }
      &.accepted_filing_card {
        position: relative;
        padding: 1.25rem 0;
        h4 {
          &.box-card-title {
            color: $theme-grey !important;
          }
        }
        &::before {
          position: absolute;
          top: -1.2rem;
          left: 0;
          width: 5.4375rem;
          height: 5.5rem;
          content: "";
          background: url("../../images/accepted_filings.svg") no-repeat left
            top;
          background-size: 100% auto;
          @media only screen and (max-width: 1200px) {
            width: 4.4375rem;
          }
        }
      }
      &.rejected-card {
        position: relative;
        padding: 1.25rem 0;
        h4 {
          &.box-card-title {
            color: $theme-grey !important;
          }
        }
        &::before {
          position: absolute;
          top: -0.5rem;
          left: 0;
          width: 5.4375rem;
          height: 5.5rem;
          content: "";
          background: url("../../images/rejected_filings.svg") no-repeat left
            top;
          background-size: 100% auto;
          @media only screen and (max-width: 1200px) {
            width: 4.4375rem;
          }
        }
      }
      &.pending_filing_card {
        position: relative;
        padding: 1.25rem 0;
        h4 {
          &.box-card-title {
            color: $theme-grey !important;
          }
        }
        &::before {
          position: absolute;
          top: -0.5rem;
          left: 0;
          width: 5.6875rem;
          height: 6.5rem;
          content: "";
          background: url("../../images/pending-icon.svg") no-repeat left top;
          background-size: 100% auto;
          @media only screen and (max-width: 1200px) {
            width: 4.4375rem;
          }
        }
      }
      h4 {
        &.box-card-title {
          font-size: 1.25rem !important;
          line-height: 1.5rem !important;
          color: $theme-white !important;
          font-family: "Lato", sans-serif !important;
        }
      }
      h3 {
        font-size: 3.75rem !important;
        color: $theme-white;
        line-height: 3.75rem;
      }

      .p-card-body {
        padding: 0rem;
        .p-card-content {
          padding: 0px;

          h4 {
            font-size: 1.25rem;
            line-height: 1.5rem;
            color: $theme-white;
          }

          h3 {
            font-size: 3.75rem;
            color: $theme-white;
            line-height: 3.75rem;
          }
        }
      }
    }
    @media only screen and (max-width: 1200px) {
      .left-card-box {
        padding-right: 2rem;
      }
      .right-card-box {
        padding-left: 2rem;
      }
      .box-card {
        padding-top: 1.3rem;
      }
      h4.white-text {
        font-size: 1.02rem !important;
      }
    }
    @media only screen and (max-width: 900px) {
      .left-card-box {
        padding-right: 0;
        margin-bottom: 20px !important;
      }
      .right-card-box {
        padding-left: 20px;
      }
      .border-left-col {
        border-left: 0;
      }
      .box-card {
        margin-bottom: 0 !important;
      }
    }
  }

  // .grid-sec{
  //   margin-top: 3.5rem;
  // }
  @media only screen and (max-width: 480px) {
    .main-title-wrap {
      &.d-flex.justify-content-between {
        display: block !important;
      }
      .material-input {
        width: 100% !important;
      }
    }
  }
}
