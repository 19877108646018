.ask-question {
  padding-right: 45px !important;
}
.faq-dtl-main {
  border-left: 3px solid $theme-mid-grey;
  @media only screen and (max-width: 599px) {
    border-left: 0;
  }

  .faq-dtl {
    .accordion-slide {
      box-shadow: none;
      border: none;
      border-radius: 0 !important;
      margin-bottom: 2.5rem;

      &:last-child {
        margin-bottom: 1.25rem;
      }

      &::before {
        display: none;
      }
      .accordion-title {
        border-bottom: 1px solid $theme-light-grey;
        > div {
          margin: 0px 0 0 0;
          padding-bottom: 0.625rem;

          span {
            width: 95%;
          }

          svg {
            font-size: 2.2rem;
            // margin-top: -30px;
          }
        }
        .MuiAccordionSummary-expandIconWrapper {
          margin-top: -30px !important;
        }
      }
    }
  }
}
.accordian-style03 {
  .css-o4b71y-MuiAccordionSummary-content {
    margin: 5px 0;
    min-height: 30px;
  }
  .css-o4b71y-MuiAccordionSummary-content.Mui-expanded {
    margin: 5px 0;
    min-height: 30px;
  }
  .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root,
  .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
    min-height: 30px;
  }
}
.css-i4bv87-MuiSvgIcon-root.accordion-arrow {
  font-size: 2.2rem;
}
.css-1elwnq4-MuiPaper-root-MuiAccordion-root.Mui-expanded {
  margin-top: 0 !important;
}
