html {
  position: relative;
  min-height: 100%;
  font-size: 16px;

  //   @media only screen and (max-width: 1024px) {
  //     font-size: 18px;
  //   }

  @media only screen and (max-width: 767px) {
    font-size: 14px;
  }
}
body {
  font-family: "Lato", sans-serif !important;
  // margin-bottom: 60px; /* Margin bottom by footer height */
  font-size: 16px;
}

ul,
ol,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
  list-style: none;
  padding: 0px;
}

a {
  // color: $color-grey;
  text-decoration: none;
  &.link {
    color: $theme-blue;
  }
  &.link:hover {
    color: $theme-btn-hover;
  }
}
