.pb-78 {
  padding-bottom: 78px;
}
.p-1 {
  padding: 8px !important;
}
.p-2 {
  padding: 16px !important;
}
.p-3 {
  padding: 24px !important;
}

.pt-0 {
  padding-top: 0px !important;
}
.pt-1 {
  padding-top: 4px !important;
}
.pt-2 {
  padding-top: 8px !important;
}
.pt-3 {
  padding-top: 16px !important;
}
.pt-4 {
  padding-top: 24px !important;
}
.pt-5 {
  padding-top: 48px !important;
}
.ps-0 {
  padding-left: 0px !important;
}
.ps-1 {
  padding-left: 4px !important;
}
.ps-2 {
  padding-left: 8px !important;
}
.ps-3 {
  padding-left: 16px !important;
}
.ps-4 {
  padding-left: 24px !important;
}
.ps-5 {
  padding-left: 48px !important;
}
.pe-0 {
  padding-right: 0 !important;
}
.pe-1 {
  padding-right: 4px !important;
}
.pe-2 {
  padding-right: 8px !important;
}
.pe-3 {
  padding-right: 16px !important;
}
.pe-4 {
  padding-right: 24px !important;
}
.pe-5 {
  padding-right: 48px !important;
}
.pb-0 {
  padding-bottom: 0px !important;
}
.pb-1 {
  padding-bottom: 4px !important;
}
.pb-2 {
  padding-bottom: 8px !important;
}
.pb-3 {
  padding-bottom: 16px !important;
}
.pb-4 {
  padding-bottom: 24px !important;
}
.pb-5 {
  padding-bottom: 48px !important;
}
.p-0 {
  padding: 0px !important;
}

.m-0 {
  margin: 0px !important;
}

.mt-0 {
  margin-top: 0px !important;
}
.mt-1 {
  margin-top: 4px !important;
}
.mt-2 {
  margin-top: 8px !important;
}
.mt-3 {
  margin-top: 16px !important;
}
.mt-4 {
  margin-top: 24px !important;
}
.mt-5 {
  margin-top: 48px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}
.mb-1 {
  margin-bottom: 4px !important;
}
.mb-2 {
  margin-bottom: 8px !important;
}
.mb-3 {
  margin-bottom: 16px !important;
}
.mb-4 {
  margin-bottom: 24px !important;
}
.mb-5 {
  margin-bottom: 48px !important;
}
.ms-0 {
  margin-left: 0px !important;
}
.ms-1 {
  margin-left: 4px !important;
}
.ms-2 {
  margin-left: 8px !important;
}
.ms-3 {
  margin-left: 16px !important;
}
.ms-4 {
  margin-left: 24px !important;
}
.ms-5 {
  margin-left: 48px !important;
}

.me-1 {
  margin-right: 4px !important;
}
.me-2 {
  margin-right: 8px !important;
}
.me-3 {
  margin-right: 16px !important;
}
.me-4 {
  margin-right: 24px !important;
}
.me-5 {
  margin-right: 48px !important;
}

.ml--16 {
  margin-left: -16px !important;
}

.ml-auto {
  margin-left: auto !important;
}
.mr-auto {
  margin-right: auto !important;
}
.mb-auto {
  margin-bottom: auto !important;
}
.mt-auto {
  margin-top: auto !important;
}

.mb-xs-5 {
  @media only screen and (max-width: 480px) {
    margin-bottom: 48px !important;
  }
}

@media only screen and (max-width: 599px) {
  .mb-xs-3 {
    margin-bottom: 1rem !important;
  }
}

@media only screen and (max-width: 900px) {
  .mt-xs-3 {
    margin-top: 3rem !important;
  }
}
