// Custome Variables
$theme-blue: #0f336d;
$theme-light-blue: #224a89;
$theme-yellow: #ffb703;
$theme-btn-hover: #0d2b5d;
$theme-orange: #fb8500;
$lighter-blue: #d6f2ff;
$lighter-blue01: #e0f5ff;
$red-pastel: #c05360;
$green-pastel: #7dcfb6;
$Primary-light: #125e73;
$theme-grey: #282828;
$theme-light-grey: #d9d9d9;
$theme-mid-grey: #b7b7b7;
$theme-lighter-grey: #f3f3f3;
$darker-mid-blue: #2b6b9a;
$blue-light: #8ecae6;
$theme-white: #fff;
$theme-black: #000;
$mid-grey: #898989;
$mid-dark-grey: #676767;
$del-err-red: #f30303;
$mid-dark-grey: #676767;
$light-grey-bg: #e3e3e3;
$theme-mid-grey-two: #898989;
$theme-error-bg: #ffebeb;
$theme-lighter-blue: #edf8ff;
$theme-dark-grey: #6f6c8f;
$lightest-blue: #edf8ff;
//Status colors
$darkgreen: #0d8802;
$lightgreen: #1fdf00;
$mustard: #DBBC55;
$deepSkyblue: #034aff;
$fireBrick: #c05360;
$brightCyan: #02aca2;

// Custome Class color variables

.theme-blue {
  color: $theme-blue !important;
}
.theme-yellow {
  color: $theme-yellow !important;
}
.theme-orange {
  color: $theme-orange !important;
}
.lighter-blue {
  color: $lighter-blue !important;
}
.red-pastel {
  color: $red-pastel !important;
}
.green-pastel {
  color: $green-pastel !important;
}
.Primary-light {
  color: $Primary-light !important;
}
.theme-grey {
  color: $theme-grey !important;
}
.theme-light-grey {
  color: $theme-light-grey !important;
}
.theme-mid-grey {
  color: $theme-mid-grey !important;
}
.darker-mid-blue {
  color: $darker-mid-blue !important;
}
.blue-light {
  color: $blue-light !important;
}
.white-text {
  color: $theme-white !important;
}
.black-text {
  color: $theme-black !important;
}
.dlt-error-color {
  color: $del-err-red;
}
.red-error {
  color: $del-err-red !important;
}
.theme-dark-grey {
  color: $theme-dark-grey !important;
}
.theme-mustard {
  color: $mustard !important;
}
// Custome Class background color variables

.theme-blue-bg {
  background: $theme-blue !important;
}
.theme-yellow-bg {
  background: $theme-yellow !important;
}
.theme-orange-bg {
  background: $theme-orange !important;
}
.lighter-blue-bg {
  background: $theme-lighter-blue !important;
}
.lighter-blue01-bg {
  background: $lighter-blue01 !important;
}

.red-pastel-bg {
  background: $red-pastel !important;
}
.green-pastel-bg {
  background: $green-pastel !important;
}
.Primary-light-bg {
  background: $Primary-light !important;
}
.theme-grey-bg {
  background: $theme-grey !important;
}
.theme-light-grey-bg {
  background: $theme-light-grey !important;
}
.theme-mid-grey-bg {
  background: $theme-mid-grey !important;
}
.darker-mid-blue-bg {
  background: $darker-mid-blue !important;
}
.blue-light-bg {
  background: $blue-light !important;
}
.light-grey-bg {
  background: $light-grey-bg !important;
}
.theme-light-blue-bg {
  background: $theme-light-blue !important;
}
.error-bg {
  background: $theme-error-bg !important;
}

.theme-green-bg {
  background: $darkgreen !important;
}
// Custome Class border color variables

.theme-blue-border {
  border-color: $theme-blue !important;
}
.theme-yellow-border {
  border-color: $theme-yellow !important;
}
.theme-orange-border {
  border-color: $theme-orange !important;
}
.lighter-blue-border {
  background: $lighter-blue !important;
}
.red-pastel-border {
  border-color: $red-pastel !important;
}
.green-pastel-border {
  border-color: $green-pastel !important;
}
.Primary-light-border {
  border-color: $Primary-light !important;
}
.theme-grey-border {
  border-color: $theme-grey !important;
}
.theme-light-grey-border {
  border-color: $theme-light-grey !important;
}
.theme-mid-grey-border {
  border-color: $theme-mid-grey !important;
}
.darker-mid-blue-border {
  border-color: $darker-mid-blue !important;
}
.blue-light-border {
  border-color: $blue-light !important;
}
.lighter-gray-border {
  border-color: $theme-lighter-grey !important;
}
