.welcome-Portal {
  background: url("../../images/welcome-bg.svg") no-repeat left bottom;
  background-size: 100% auto;
  width: 100%;
  padding: 5.6875rem 0 2.25rem 0;

  .welcome-Portal-dtl {
    max-width: 600px;
    margin: 0 auto;
  }
  @media only screen and (max-width: 992px) {
    padding: 3.6rem 0 2.25rem 0;
  }
  @media only screen and (max-width: 600px) {
    padding: 2.6rem 0 2.25rem 0;
  }
}
.sub-title-text {
  font-size: 1.125rem;
  line-height: 1.875rem;
  color: $Primary-light;
  font-family: "Hanuman", serif;
  margin-bottom: 1.375rem;
  display: block;
}
.our-features {
  padding: 6.25rem 0;
  position: relative;
  background: url("../../images/I2File-text-logo.svg") no-repeat center bottom;
  background-size: 57% auto;

  @media only screen and (max-width: 992px) {
    padding: 3.6rem 0;
    .css-1ss2ei0-MuiContainer-root {
      max-width: 100%;
    }
  }
  @media only screen and (max-width: 600px) {
    padding: 2.6rem 0;
  }
  @media only screen and (max-width: 480px) {
    .MuiGrid-grid-xs-6 {
      max-width: 100%;
      flex-basis: 100%;
    }
  }
}
.features-img {
  img {
    height: 61px;
  }
}

.our-systems {
  background: url("../../images/our-systems-image.jpg") no-repeat left top;
  background-size: cover;
  width: 100%;
  padding: 2.375rem 0;

  @media only screen and (min-width: 600px) {
    .css-1ss2ei0-MuiContainer-root {
      max-width: 85%;
    }
  }
  @media only screen and (max-width: 900px) {
    h2.pb-78 {
      padding-bottom: 40px;
    }
    .img-caption h5 {
      color: #fff;
      font-size: 1rem;
      line-height: 1.4rem;
      margin-top: 0.8rem;
    }
  }
}
.more-system-main {
  background-color: $theme-blue !important;
  text-align: left !important;
}
.more-system-col {
  position: relative;
  &:before {
    content: "";
    position: absolute;
    top: 26px;
    right: 6px;
    width: 19px;
    height: 35px;
    background: url("../../images/white-right-arrow.svg") no-repeat left top;
    margin-left: 0;
    @media only screen and (max-width: 900px) {
      display: none;
    }
    @media only screen and (min-width: 600px) {
      // top: 5px;
      // right: -1.5rem;
    }
  }
}
.more-systems {
  position: relative;
  display: inline-block;
  // text-align: center;
  padding-left: 10px;

  @media only screen and (max-width: 767px) {
    padding-left: 0;
  }

  a {
    font-size: 1.125rem;
    line-height: 1.375rem;
    color: $theme-white;

    @media only screen and (max-width: 1280px) {
      font-size: 0.9375rem;
    }

    @media only screen and (max-width: 992px) {
      font-size: 14px;
      line-height: 20px;
    }
  }

  @media only screen and (max-width: 900px) {
    margin-top: 0px;
    .css-1ss2ei0-MuiContainer-root {
      max-width: 100%;
    }
    a {
      line-height: 0;
      br {
        display: none;
      }
    }
  }
}
.img-caption {
  .img-caption-bg {
    background: $theme-grey;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
  }
  h3 {
    color: $theme-white !important;
    font-size: 1.125rem !important;
    line-height: 1.375rem;
    margin-top: 0.3125rem;
    text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.7);
    @media only screen and (max-width: 1366px) {
      font-size: 1rem !important;
      line-height: 1.4rem;
      margin-top: 0.2rem;
    }
    @media only screen and (max-width: 1200px) {
      font-size: 0.875rem !important;
      line-height: 20px !important;
    }
    @media only screen and (max-width: 992px) {
      font-size: 0.75rem !important;
      line-height: 15px !important;
    }
  }
}
.faq-sec {
  padding: 6.25rem 0 4.375rem 0;
  max-width: 62.5rem;
  margin: 0 auto;
  @media only screen and (max-width: 900px) {
    padding: 4.25rem 0 4.375rem 0;
    max-width: 100%;
    margin: 0 auto;
    .css-1ss2ei0-MuiContainer-root {
      max-width: 100%;
    }
  }
  @media only screen and (max-width: 600px) {
    .ask-question {
      padding-right: 0 !important;
      margin-bottom: 15px;
      &.text-right {
        text-align: center !important;
      }
    }
    .faq-dtl {
      h4 {
        font-size: 1.1rem !important;
      }
    }
  }
}

.text-note .newsite-msg{
  font-size: 14px !important;
  margin-bottom: 0px !important;
  padding: 0px  !important;
}

.text-note{
  padding:10px 0px !important
}
// h2 {
//   @media only screen and (max-width: 600px) {
//     font-size: 1.8rem !important;
//   }
// }
