.tabs-supportmsg {
  background-color: $theme-light-blue;
  padding: 0.625rem 0.9375rem;
  font-family: "Lato", sans-serif !important;
  color: $theme-white;
  a {
    font-weight: 400;
    font-size: 0.9375rem;
    line-height: 1.375rem;
    color: $theme-yellow;
  }
}

.vertical-tabs {
  @media only screen and (max-width: 460px) {
    display: block !important;
  }
  .vertical-tabs-col {
    background-color: $theme-light-blue;
    color: $theme-white;
    margin-bottom: 0.125rem;
    &.Mui-selected {
      background-color: $theme-blue;
      color: $theme-white;
    }
  }
  .vertical-tabs-left {
    width: 25%;
    @media only screen and (max-width: 460px) {
      width: 100%;
    }
  }
  .vertical-tabs-col-right {
    padding-left: 1.875rem;
    width: 75%;
    @media only screen and (max-width: 460px) {
      padding-left: 0;
      width: 100%;
    }
    .accordion-title {
      border-bottom: 1px solid $theme-light-grey;
    }
    .css-19kzrtu {
      padding: 0px;
    }
    .vertical-tabs-dtl {
      .css-1elwnq4-MuiPaper-root-MuiAccordion-root {
        box-shadow: none;

        &::before {
          opacity: 9;
        }
      }
      .css-o4b71y-MuiAccordionSummary-content {
        &.Mui-expanded {
          margin: 20px 0px;
        }
        h4 {
          // color: $theme-blue !important;
          width: 90%;
        }
      }
      .css-yw020d-MuiAccordionSummary-expandIconWrapper {
        position: absolute;
        top: 1.25rem;
        right: 0;
      }
      ol {
        list-style: auto;

        li {
          margin-bottom: 0.9375rem;
        }
      }
    }
  }
}
