.login-col {
  position: relative;
}

.login_box {
  position: absolute;
  top: 100%;
  right: -100%;
  width: 28.125rem;
  z-index: 2;
  background: #fff;
  padding: 2.6875rem 3.125rem 2.6875rem 3.125rem;
  border-radius: 0.9375rem;
  -webkit-box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.5);

  @media only screen and (max-width: 420px) {
    width: 22.125rem;
  }

  @media only screen and (max-width: 360px) {
    width: 20.125rem;
  }

  .filed-slide {
    .forgot-password {
      a {
        font-size: 16px;
        color: $theme-grey;

        &:hover {
          color: $theme-blue;
          text-decoration: underline;
        }
      }
    }
  }

  &.login-box-show {
    display: block;
  }
}

.admin_login_box {
  position: absolute;
  top: 100%;
  right: 10%;
  width: 28.125rem;
  z-index: 2;
  background: #fff;
  padding: 2.6875rem 3.125rem 2.6875rem 3.125rem;
  border-radius: 0.9375rem;
  -webkit-box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.5);

  .filed-slide {
    .forgot-password {
      a {
        font-size: 16px;
        color: $theme-grey;

        &:hover {
          color: $theme-blue;
          text-decoration: underline;
        }
      }
    }
  }

  &.login-box-show {
    display: block;
  }
}

.register-col {
  position: relative;
  // &:hover {
  //   .register-account {
  //     display: block;
  //   }
  // }

  .register-account {
    min-width: 17.5rem;
    position: absolute;
    top: 100%;
    right: 0;
    background: #fff;
    padding: 1.25rem 0;
    border-radius: 0.9375rem;
    z-index: 2;
    -webkit-box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.5);
    display: block;

    li {
      font-size: 1rem;
      height: 2.0625rem;
      line-height: 2.0625rem;
      margin-bottom: 0.625rem;
      position: relative;

      &:last-child {
        margin-bottom: 0;
      }
      a {
        color: $theme-grey;
        padding: 0 1.875rem 0 3.4375rem;
        display: block;

        .icon-set-position {
          position: absolute;
          top: 3px;
          left: 15px;

          svg {
            color: $theme-blue;
          }
        }
      }

      &:hover {
        background-color: $lighter-blue;

        a {
          color: $theme-blue;
        }
      }
      &.active {
        background-color: $lighter-blue;

        a {
          color: $theme-blue;
        }
      }
    }
  }
}

.forgot-link {
  @media only screen and (max-width: 420px) {
    display: block !important;
    span {
      display: block;
      margin-bottom: 10px;
    }
  }
}
