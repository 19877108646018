.firm-info-main {
  .helpful-information-sec {
    background: $lighter-blue;
    padding: 0.9375rem 1.5625rem;
    margin-bottom: 1.875rem;
    h5 {
      font-family: "Lato", sans-serif;
      font-size: 1.125rem;
      font-weight: 700;
      line-height: 1.35rem;
    }
    .helpful-information-list {
      margin-top: 0.9375rem;
      li {
        font-size: 0.875rem;
        font-weight: 400;
        margin-bottom: 0.3125rem;
        color: $theme-black;
        padding-left: 1.5625rem;
        position: relative;

        &::before {
          position: absolute;
          content: "";
          top: 0.3125rem;
          left: 0.5rem;
          width: 0.5rem;
          height: 0.5rem;
          background: #000;
          border-radius: 50%;
        }
      }
    }
  }
  .user-sgreement-sec {
    font-size: 1rem;
    font-weight: 700;
    line-height: 2.5rem;
  }
  .bottom-button-row {
    margin-top: 60px;
  }
}
.complete-registration-row {
  border: 0.0625rem solid $theme-light-grey;
  margin-bottom: 3.125rem;

  .complete-registration-top-banner {
    background-color: rgba(125, 207, 182, 0.4);
    padding: 0.625rem 0;
    .complete-registration-text {
      margin-left: -3.125rem;
      h3 {
        font-size: 1.5rem;
        font-weight: 900;
        color: $theme-blue;
      }
      h5 {
        font-size: 1.125rem;
        color: $theme-black;
        font-weight: 500;
        margin-top: 0.3125rem;
      }
    }
  }

  .complete-registration-content {
    padding: 2.25rem 2.5rem;

    ul {
      margin-top: 1.25rem;
      li {
        font-size: 18px;
        color: $theme-black;
        font-weight: 500;
        margin-bottom: 0.9375rem;
        label {
          width: 12.5rem;
          display: inline-block;
        }
      }
    }
  }
}

.roles-checkbox {
  @media only screen and (min-width: 576px) {
    display: flex;
    align-items: center;
  }
}

.checkbox-link {
  color: $theme-blue;
  text-decoration: underline;
}
