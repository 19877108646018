.text-center{
    text-align: center !important;
}
.text-left{
    text-align: left !important;
}
.text-right{
    text-align: right !important;
}
.vertical-align-text-top{
    vertical-align: text-top;
}
.vertical-align-text-bottom{
    vertical-align: text-bottom;
}
.vertical-align-top{
    vertical-align:top;
}
.vertical-align-bottom{
    vertical-align:bottom;
}
.vertical-align-middle{
    vertical-align:middle;
}