.btn {
  font-size: 1rem !important;
  font-weight: 700 !important;
  font-family: "Lato", sans-serif !important;
  text-transform: capitalize !important;
  padding: 0 1.25rem !important ;
  height: 2.25rem !important;
  line-height: 2.25rem !important;
  color: $theme-white !important;
  border-radius: 0.5rem !important;

  &.primary-button {
    background-color: $theme-blue !important;

    &.mar-0 {
      margin-right: 0px !important;
    }

    &:hover {
      background-color: $theme-btn-hover !important;
    }
    &.Mui-disabled {
      // color: rgba(0, 0, 0, 0.26) !important;
      // background-color: rgba(0, 0, 0, 0.12) !important;
      // border: 1px solid rgba(0, 0, 0, 0.12) !important;
      opacity: .5;
    }
  }
  &.button-outline-secondary {
    border: 1px solid $theme-blue !important;
    background-color: $theme-white !important;
    color: $theme-blue !important;

    &.mar-0 {
      margin-right: 0px !important;
    }

    &:hover {
      background-color: $theme-btn-hover !important;
      color: $theme-white !important;
    }
    &.Mui-disabled {
      color: rgba(0, 0, 0, 0.26) !important;
      background-color: rgba(0, 0, 0, 0.12) !important;
      border: 1px solid rgba(0, 0, 0, 0.12) !important;
    }
  }
  &.btn-yellow-bg {
    background-color: $theme-yellow !important;
    color: $theme-grey !important;
    padding: 0 1.125rem !important;
    &:hover {
      background-color: $theme-btn-hover !important;
      color: $theme-white !important;
    }
    &.Mui-disabled {
      color: rgba(0, 0, 0, 0.26) !important;
      background-color: rgba(0, 0, 0, 0.12) !important;
      border: 1px solid rgba(0, 0, 0, 0.12) !important;
    }
  }

  @media only screen and (max-width: 1200px) {
    font-size: 0.895rem !important;
  }
}

.Mui-focusVisible {
  &.MuiCheckbox-root {
    svg {
      outline: 2px solid $theme-blue !important;
      outline-offset: -1px;
    }
  }
}

.pick-drop-button {
  background-color: $blue-light !important;
  box-shadow: none !important;
  color: $theme-black !important;
  &.Mui-disabled {
    color: rgba(0, 0, 0, 0.26) !important;
    background-color: rgba(0, 0, 0, 0.12) !important;
  }
}

// :focus {
//   outline: 2px solid $theme-blue !important;
//   outline-offset: -1px;
// }

.custom-font-size {
  font-size: 0.8rem !important;
}
