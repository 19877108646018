@media only screen and (max-width: 992px) {
  .nav-listing {
    button {
      font-size: 1rem !important;
      margin-right: 1rem !important;
    }
  }
  .btn-col {
    button {
      font-size: 1rem !important;
      padding: 0 1.125rem !important;
    }
    .btn.primary-button {
      margin-right: 10px !important;
    }
    .btn.button-outline-secondary {
      margin-right: 5px !important;
    }
  }
  .logo {
    img {
      height: 60px;
    }
  }
  .css-hyum1k-MuiToolbar-root {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .text-note p {
    font-size: 1rem;
    line-height: 22px;
    padding: 5px 0 8px 0;
  }
}
@media only screen and (max-width: 600px) {
  .css-hyum1k-MuiToolbar-root {
    display: flex !important;
    justify-content: center !important;
  }
  .logo {
    img {
      height: 40px;
    }
  }
}

.menu-bar {
  svg {
    color: $theme-blue;
    font-size: 30px;
  }
}
.mobile-menu {
  h6 {
    img {
      height: 60px;
    }
  }
  ul {
    padding: 0;
    li {
      background-color: $lighter-blue;
      margin-bottom: 10px;
    }
  }
}

@media only screen and (max-width: 480px) {
  .main_content {
    .main_content-top {
      padding-top: 10px;
      .main_content-top-note {
        padding-right: 15px !important;
      }
    }
  }
}

.header-marquee {
  color: $theme-blue;
  @media only screen and (max-width: 900px) {
    display: none;
  }
}

.skip-to-main-content-link {
  position: absolute;
  left: -9999px;
  z-index: 999;
  padding: 5px;
  border: 1px solid $theme-blue;
  color: $theme-blue;
  opacity: 0;
  font-size: 0.875rem;
  display: block;
}

.skip-to-main-content-link:focus {
  left: 50%;
  transform: translateX(-50%);
  opacity: 1;
}
