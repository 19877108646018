body {
  color: $theme-grey;
}
.container-fluid {
  padding: 0 1.25rem;
}
.inner-section {
  margin-top: 4.375rem;
}
*,
::after,
::before {
  box-sizing: border-box;
}

.remove-item-css {
  box-shadow: none !important;
  border-radius: 0 !important;
  background: none !important;
  padding: 0px !important;
  font-size: inherit !important;
  font-weight: inherit !important;
  line-height: inherit !important;
  letter-spacing: inherit !important;
  color: inherit !important;
  text-align: left !important;
  margin: 0px !important;
  font-family: "Lato", sans-serif !important;
  &::before {
    display: none;
  }
}
.select-box {
  > div {
    &:focus {
      background: inherit !important;
    }
  }
}

.content-wrapper {
  padding: 1.5625rem 3.125rem;
  background-color: $theme-white;

  @media only screen and (max-width: 1200px) {
    padding: 1rem 1rem;
  }

  @media only screen and (max-width: 767px) {
    padding: 1.5625rem;
  }
}
.content-row {
  margin: 4.25rem 0;
}

.img-fluid {
  width: 100%;
  max-width: 100%;
}

.note-line {
  padding: 0.75rem 0.625rem;
  margin-top: 1.625rem;

  p {
    line-height: 1.5rem;
    font-size: 1rem;

    @media only screen and (max-width: 768px) {
      font-size: 0.875rem;
      line-height: 1.25rem;
    }
  }

  svg {
    vertical-align: top;
    @media only screen and (max-width: 768px) {
      font-size: 1.25rem;
    }
  }
}

.accordion-row {
  h5 {
    font-size: 1.125rem;
    font-family: "Hanuman", serif !important;
    line-height: 1.375rem;
    width: 80%;
  }
  .checkbox-title {
    span {
      font-size: 1.125rem;
      font-family: "Hanuman", serif !important;
      line-height: 1.375rem;
      font-weight: 700;

      @media only screen and (max-width: 768px) {
        font-size: 0.875rem;
        line-height: 1.25rem;
      }
    }
  }
  .accordion-arrow {
    font-size: 2.5rem;
    margin-top: -0.3125rem;
  }

  .accordion-info-title {
    min-height: inherit !important;
    padding: 0.75rem 1rem;
    > div {
      margin: 0 !important;
    }
  }

  .miui-accordion-region {
    padding: 1rem;
  }

  .accordion-col {
    margin-bottom: 2.5rem;

    @media only screen and (max-width: 768px) {
      margin-bottom: 1.25rem;
    }
  }
}
// Pop up
.popup-main {
  &.popup-sm {
    .MuiDialog-paper {
      min-width: 350px;
    }
  }
}

.MuiDialogContent-root {
  padding-top: 0 !important;
}

.grey-card {
  background: $theme-lighter-grey;
}

.v-align {
  vertical-align: middle;
}

.confirmation-dialog-action {
  justify-content: center !important;
}

.box-shadow-none {
  box-shadow: none !important;
}

.delete-icon-size {
  font-size: 40px;
}

.attached-cases-list {
  .MuiBox-root {
    border-bottom: 0;
    &:last-child {
      border-bottom: 1px solid lightgray;
    }
  }
}

.payment-summary {
  border: 1px solid $theme-light-grey;
  padding: 1.25rem;
  .float-left {
    float: left;
  }
  .text-right {
    @media only screen and (max-width: 599px) {
      text-align: left !important;
      span {
        margin-right: 5px;
      }
    }
  }
}

.last-days {
  font-size: 0.875rem;
}

.MuiBackdrop-root {
  &.section-loader {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.2);
  }
}

.export-csv {
  a {
    color: $theme-blue;
  }
  &:hover {
    a {
      color: $theme-white;
    }
  }
}

.MuiDialogTitle-root {
  @media only screen and (max-width: 480px) {
    flex: auto !important;
  }
}

.attornery-list {
  display: inline-flex;
  align-items: center;
  .MuiListItemIcon-root {
    min-width: 25px;
  }
}

.add-attorney-list {
  max-width: 350px;
  white-space: initial;
  word-break: break-all;
  width: 100%;
}
