.material-input {
  font-size: 2rem;
  line-height: 1.25rem;
  padding: 0.5625rem 2.5rem 0.5625rem 0.5625rem;
  color: $theme-grey;

  &:focus {
    outline: none !important;
    box-shadow: none !important;
    border-color: $theme-blue;
  }
}

.MuiFormLabel-root {
  color: $theme-black !important;
  font-family: "Lato", sans-serif !important;
}

.MuiInputBase-root {
  font-family: "Lato", sans-serif !important;
}

.label-checkbox {
  display: flex !important;
  align-items: center !important;
}

.material-input input::-webkit-input-placeholder {
  /* Edge */
  color: $mid-dark-grey;
}

.material-input input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: $mid-dark-grey;
}

.material-input input::placeholder {
  color: $mid-dark-grey;
}

.label-print-name {
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 28px !important;
  color: $mid-grey;
}
.label-print-field {
  font-weight: 600 !important;
  font-size: 15px !important;
  line-height: 28px !important;
  color: inherit;
}

.MuiCheckbox-root {
  &.Mui-checked {
    .MuiSvgIcon-root {
      color: $theme-blue !important;
    }
  }
}

.checkbox-padding-left-0 {
  .MuiCheckbox-root {
    padding-left: 0;
  }
}

.MuiRadio-root {
  &.Mui-checked {
    color: $theme-blue !important;
  }
}

.checkbox-align {
  vertical-align: middle;
}

.date-picker {
  fieldset {
    border-radius: 0;
    border-width: 0 0 1px 0;
    border-color: rgba(0, 0, 0, 0.4);
  }
  input {
    padding: 12.5px 14px 12.5px 0;
  }
  label {
    left: -15px;
  }
}

.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-width: 0 0 2px 0 !important;
}
