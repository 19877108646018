.case-id-title {
  background: $theme-white;
  padding: 0.875rem 1.6875rem;
  border-radius: 3.125rem;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
  text-align: center;

  @media only screen and (max-width: 767px) {
    width: 90%;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
}
.delete-icon-red {
  color: $del-err-red;
}

.bottom-btn {
  .btn {
    &.primary-button {
      @media only screen and (max-width: 480px) {
        margin-right: 10px !important;
      }
    }
  }
}

.case-cross-ref-num {
  position: relative;
  padding: 1.25rem 0;
  @media only screen and (max-width: 767px) {
    padding: 1.25rem 0 0.625rem 0;
  }
  .case-id-title {
    position: absolute;
    top: -1.4375rem;
    left: 50%;
    margin-left: -14.375rem;
    z-index: 1;

    @media only screen and (max-width: 767px) {
      position: static;
      margin-left: 0rem;
      margin-top: -3.125rem;
      width: 90%;
      font-size: 0.875rem;
      line-height: 1.25rem;
    }
  }
  .accordion-head {
    padding: 0 1.875rem;

    .accordion-arrow {
      font-size: 3.125rem;
      margin-top: -0.3125rem;

      @media only screen and (max-width: 767px) {
        font-size: 1.875rem;
      }
    }
  }
  .accordion-cont {
    padding: 0 1.875rem;

    .accordion-details-row {
      .accordion-inner-row {
        background: $theme-white;
        border: 0.0625rem solid $theme-light-grey;
        padding: 1.125rem 0.625rem;
        margin-bottom: 0.625rem;
        position: relative;
        p {
          label {
            font-size: 1rem;
            font-weight: 500;
            color: $mid-dark-grey;
            @media only screen and (max-width: 767px) {
              display: block;
              margin-bottom: 0.625rem;
            }
          }
          span {
            font-size: 1rem;
            font-weight: 700;
            color: $theme-black;
            padding-left: 0.3125rem;
            @media only screen and (max-width: 767px) {
              display: block;
            }
          }
        }
        .case-delete {
          position: absolute;
          top: 0;
          right: 0.625rem;
        }
      }
    }
  }
}
.existing-case-parties {
  margin-top: 3.75rem;

  @media only screen and (max-width: 768px) {
    margin-top: 2.5rem;
  }

  .dash-title-h3 {
    color: $theme-blue !important;
  }
  .existing-case-parties-top {
    @media only screen and (max-width: 768px) {
      display: block !important;
      margin-bottom: 1.25rem;
    }
    h5 {
      line-height: 2.25rem;
      font-size: 1.25rem;
      font-weight: 600;
      color: $theme-black;
      @media only screen and (max-width: 992px) {
        width: 60%;
      }
      @media only screen and (max-width: 768px) {
        margin-bottom: 0.625rem;
        line-height: 1.375rem;
        font-size: 1.125rem;
        width: 100%;
      }

      span {
        line-height: 1.1875rem;
        font-size: 1rem;
        font-weight: 600;
        color: $theme-mid-grey;
      }
    }
  }

  .additional-parties-col {
    border: 1px solid $theme-light-grey;
    padding: 1.25rem;
    margin-top: 1.5rem;

    .additional-parties-col-head {
      border-bottom: 1px solid $theme-light-grey;
      padding-bottom: 0.625rem;

      p {
        font-size: 1rem;
        color: $mid-dark-grey;
        font-weight: 600;
        font-family: "Lato", sans-serif;
        @media only screen and (max-width: 768px) {
          font-size: 0.875rem;
        }
        span {
          font-weight: 700;
          color: $theme-black;
          margin-left: 0.3125rem;
        }
      }
    }
    .additional-parties-col-cont {
      margin-top: 0.625rem;
      p {
        font-size: 1rem;
        color: $mid-dark-grey;
        font-weight: 600;
        font-family: "Lato", sans-serif !important;

        @media only screen and (max-width: 768px) {
          font-size: 0.875rem;
        }

        span {
          font-weight: 600;
          color: $theme-black;
          margin-top: 0.3125rem;
          display: block;
        }
      }
    }
  }
  .case-filer-dtl {
    background: $theme-white;
    padding: 1.5625rem;
    // margin-top: 3.75rem;
    margin-top: 1rem;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);

    .case-filer-dtl-top {
      @media only screen and (max-width: 768px) {
        display: block !important ;
      }
      h4 {
        color: $theme-blue !important;
        line-height: 2.625rem;
      }
    }

    .check-box-control {
      span {
        font-size: 0.875rem;
        line-height: 1.25rem;
      }
    }
    .radio-control {
      span {
        font-size: 0.875rem;
        line-height: 1.25rem;
      }
    }
  }
}
.upload-error-msg {
  background: $light-grey-bg;
  padding: 0.875rem 1.25rem;
  font-size: 0.875rem;
  line-height: 1.05rem;
  margin-top: 1.875rem;

  .close-error {
    font-size: 1.125rem;
    color: $del-err-red;
    float: right;
  }
}

.payment-fees {
  border: 1px solid $theme-lighter-grey;
  padding: 1.5625rem;
  margin-top: 1.75rem;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
  @media only screen and (max-width: 768px) {
    margin-top: 1.25rem;
  }
  h4 {
    font-size: 1.5rem !important;
    line-height: 2.1875rem;
    @media only screen and (max-width: 768px) {
      font-size: 1.125rem !important;
      line-height: 1.625rem;
    }
  }

  .payment-icon {
    font-size: 3.125rem;
    @media only screen and (max-width: 768px) {
      font-size: 1.875rem;
    }
  }
}

.summary-row {
  border-bottom: 0.0625rem solid $theme-mid-grey;
  padding-bottom: 1.75rem;
}

.existing-case-summary {
  margin-top: 3.75rem;

  @media only screen and (max-width: 768px) {
    margin-top: 2.5rem;
  }
  .existing-case-summary-top {
    margin-bottom: 0.9375rem;
    h5 {
      // line-height: 2.25rem;
      font-size: 1.25rem;
      font-weight: 600;
      color: $theme-black;
    }
  }
}

.accordions-edit {
  font-size: 1rem;
  line-height: 1.5rem;
  position: absolute;
  top: 0.875rem;
  right: 5rem;
  @media only screen and (max-width: 576px) {
    right: 4.0625rem;
  }
  svg {
    font-size: 1.1875rem;
    margin-top: -0.125rem;
  }

  span {
    @media only screen and (max-width: 576px) {
      display: none;
    }
  }
}
.case-dtl-row {
  h6 {
    font-size: 1.125rem;
    font-weight: 500;
    color: $theme-grey;
    margin-top: 0.3125rem;
    border-top: 1px solid $theme-mid-grey;
    padding-top: 0.9375rem;
    padding-bottom: 0.625rem;
  }
  p {
    span {
      font-size: 0.9375rem;
      color: $theme-mid-grey-two;
      font-weight: 500;
      margin-bottom: 0.3125rem;
    }
    label {
      font-size: 1rem;
      color: $theme-black;
      font-weight: 600;
    }
  }
}

.services-contacts {
  border-bottom: 0.0625rem solid $theme-lighter-grey;
  padding: 0.3125rem 0;
  @media only screen and (max-width: 768px) {
    display: block !important;
    border-bottom: 1px solid $light-grey-bg;
    margin-bottom: 0.625rem;
    padding: 0.625rem 0;
  }
  .ser-cont-dtl {
    @media only screen and (max-width: 768px) {
      margin-bottom: 0.625rem;
    }
    span {
      font-weight: 500;
      font-family: "Lato", sans-serif !important;
      color: $mid-dark-grey;
    }
  }
  .ser-cont-act {
    ul {
      li {
        svg {
          font-size: 1.125rem;
        }
      }
    }
  }
}
.service-button-row {
  @media only screen and (max-width: 768px) {
    display: block !important;
  }
  .button-outline-secondary {
    margin-bottom: 0.625rem;
  }
}

.payment-box {
  background: $lightest-blue;
  font-family: "Lato", sans-serif;
  position: relative;
  .payment-box-row {
    border-bottom: 1px solid $theme-mid-grey;
    font-size: 14px;
    padding-bottom: 0.875rem;
    margin-bottom: 0.875rem;
    font-weight: 600;

    @media only screen and (max-width: 560px) {
      font-size: 14px;
    }

    @media only screen and (max-width: 360px) {
      font-size: 12px;
    }

    &:last-child {
      border-bottom: 0;
      margin-bottom: 0;
      padding-bottom: 0;
    }

    span {
      color: $mid-dark-grey;
    }

    .w-50 {
      @media only screen and (max-width: 560px) {
        width: 100% !important;
      }
    }

    strong {
      font-weight: bold;
    }

    .payment-box-name {
      @media only screen and (max-width: 767px) {
        margin-bottom: 15px;
      }
    }

    &.d-md-flex {
      @media only screen and (max-width: 767px) {
        .w-50 {
          width: 100% !important;
        }
      }
    }
  }
}

.disclaimer-note {
  p {
    color: #c20000;
  }
}

.section-message-container {
  position: absolute;
  color: #214785;
  background-color: rgba(255, 255, 255, 0.6);
  z-index: 5;
  width: 100%;
  height: 100%;
  top: 50%;
  text-align: center;
  left: 50%;
  transform: translate(-50%, -50%);

  label {
    position: absolute;
    top: 50%;
    left: 30%;
  }

  label.text-danger {
    color: $del-err-red;
  }
}

.checkbox-mob {
  @media only screen and (max-width: 900px) {
    margin-left: 0 !important;
    white-space: normal !important;
    .MuiCheckbox-root {
      padding-left: 0;
    }
  }
}
