h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-family: "Lato", sans-serif;
}

h2,
.h2 {
  font-size: 2rem !important;
  color: $theme-grey !important;
  font-weight: 700 !important;
  font-family: "Hanuman", serif !important;

  @media only screen and (max-width: 1024px) {
    font-size: 1.5rem !important;
    line-height: 2.125rem !important;
  }
  @media only screen and (max-width: 767px) {
    font-size: 1.25rem !important;
    line-height: 1.875rem !important;
  }
}
h3,
.h3 {
  font-size: 1.5rem !important;
  color: $theme-grey !important;
  font-weight: 700 !important;
  font-family: "Hanuman", serif;

  @media only screen and (max-width: 1024px) {
    font-size: 1.125rem !important;
    line-height: 2.375rem !important;
  }
  @media only screen and (max-width: 767px) {
    font-size: 1rem !important;
    line-height: 1.75rem !important;
  }
}
h4,
.h4 {
  font-size: 1.25rem !important;
  color: $theme-grey !important;
  font-weight: 700 !important;
  font-family: "Hanuman", serif;

  @media only screen and (max-width: 1024px) {
    font-size: 1.125rem !important;
    line-height: 2.375rem !important;
  }
  @media only screen and (max-width: 767px) {
    // font-size: .875rem !important;
    line-height: 1.75rem !important;
  }
}
h5,
.h5 {
  font-size: 1rem !important;
  color: $theme-blue !important;
  font-weight: 700 !important;
}

.dash-title-h2 {
  font-size: 1.875rem !important;
  line-height: 2.5rem !important;
  font-weight: 700 !important;
  color: $theme-blue !important;
  position: relative !important;
  padding-left: 0.9375rem !important;

  @media only screen and (max-width: 1200px) {
    font-size: 1.5rem !important;
    line-height: 2.125rem !important;
  }
  @media only screen and (max-width: 1024px) {
    font-size: 1.25rem !important;
    line-height: 1.875rem !important;
  }

  &:before {
    width: 0.3125rem !important;
    height: 1.8125rem !important;
    background-color: $blue-light !important;
    position: absolute !important;
    top: 2px !important;
    left: 0px !important;
    content: "" !important;
  }
}
.dash-title-h3 {
  font-size: 1.5rem !important;
  line-height: 2.1875rem !important;
  color: $darker-mid-blue !important;
  font-weight: 700 !important;
  font-family: "Hanuman", serif !important;

  @media only screen and (max-width: 1200px) {
    font-size: 1.25rem !important;
    line-height: 1.875rem !important;
  }
  @media only screen and (max-width: 1024px) {
    font-size: 1.125rem !important;
    line-height: 1.75rem !important;
  }
}

.MuiTypography-root {
  font-family: "Lato", sans-serif !important;
  &.dash-title-h3,
  &.popup-title {
    font-family: "Hanuman", serif !important;
  }
}
