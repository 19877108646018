.p-tabview {
  .p-tabview-nav-content {
    margin-bottom: 4.1875rem;

    @media only screen and (max-width: 767px) {
      margin-bottom: 2.5rem;
    }

    .p-tabview-nav {
      .MuiTabs-scroller {
        .MuiTabs-flexContainer {
          justify-content: center !important;
          display: flex;

          @media only screen and (max-width: 767px) {
            display: block;
          }
        }
      }
      .p-tabview-nav-link {
        padding: 0.6875rem 3.125rem;
        border-radius: 0.5rem;
        background: $lighter-blue;
        color: $theme-blue;
        font-weight: 700;
        font-size: 1.125rem;
        margin: 0px;
        border: none;
        margin-right: 1.25rem;
        position: relative;
        overflow: visible;
        text-transform: capitalize;
        font-family: "Lato", sans-serif !important;
        min-width: inherit;
        max-width: inherit;
        letter-spacing: normal;
        min-height: 3rem;
        &:focus {
          box-shadow: none !important;
        }
        &:hover {
          background: $theme-yellow !important;
          color: $theme-grey !important;
        }
        &:after {
          width: 100%;
          background: $lighter-blue;
          height: 4px;
          position: absolute;
          top: 1.125rem;
          left: 1.875rem;
          content: "";
          z-index: -1;
        }
        &.tabe-last {
          margin-right: 0rem;
          &:after {
            display: none;
          }
        }
        &:last-child {
          margin-right: 0rem;
        }
        &.Mui-selected {
          &.p-tabview-nav-link {
            border: none;
            background: $theme-yellow;
            color: $theme-grey !important;
          }
        }

        @media only screen and (max-width: 1440px) {
          padding: 0.6875rem 1.875rem;
        }
        @media only screen and (max-width: 1280px) {
          padding: 0.6875rem 0.9375rem;
        }
        @media only screen and (max-width: 1100px) {
          font-size: 0.875rem;
          line-height: 0.875rem;
          min-height: inherit;
          margin-right: 0.625rem;
        }
        @media only screen and (max-width: 992px) {
          // font-size: .75rem;
          // line-height: .875rem;
          min-height: inherit;
          padding: 0.6875rem;
        }
        @media only screen and (max-width: 767px) {
          // font-size: .875rem;
          // line-height: .875rem;
          min-height: inherit;
          margin-right: 0;
          padding: 0.6875rem 1.25rem;
          width: 100%;
          display: block;
          margin-bottom: 0.625rem;
        }
      }
      .MuiTabs-indicator {
        display: none;
      }
    }
  }
}

.basicTab {
  .basic-tab-header {
    button.Mui-selected {
      color: $theme-blue;
    }
  }
  .basic-tab-content {
    .MuiBox-root {
      padding: 20px 0;
    }
  }
}
