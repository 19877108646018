$max-width: 1500;

@for $i from 1 through $max-width {
    $value: $i * 1;
    .width#{$value} { width: $i+px;}
}

$max-height: 350;

@for $i from 1 through $max-height {
    $value: $i * 1;
    .height#{$value} { height: $i+px;}
}

$font-size: 50px;

@for $i from 1 through $font-size {
    $value: $i * 1;
    .f-size#{$value} { font-size: $i+px;}
}

.f-w-light{
    font-weight: 300 !important;
}
.f-w-regular{
    font-weight: 400 !important;
}
.f-w-medium{
    font-weight: 500 !important;
}
.f-w-semibold{
    font-weight: 600 !important;
}
.f-w-bold{
    font-weight: 700 !important;
}
.f-w-black{
    font-weight: 900 !important;
}
.f-size18{
    font-size: 18px;
}

.f-16{
    font-size: 1rem;
}
.f-lato{
    font-family: 'Lato', sans-serif !important;
}