/* Table Grid ROund Number */
.round-number {
  background: $theme-grey;
  color: #fff;
  border-radius: 18px;
  padding: 4px 15px;
  min-width: 62px;
  min-height: 30px;
  font-weight: bold;
}

// grid table header background
.themeDataTable {
  .MuiDataGrid-columnHeaders {
    background-color: $lighter-blue;
  }
}
.gray-themeDataTable {
  .MuiDataGrid-columnHeaders {
    background-color: $theme-light-grey;
  }
}

.dataGridactionIcons {
  color: $theme-blue;
  svg {
    color: $theme-blue;
  }
  .css-i4bv87-MuiSvgIcon-root {
    font-size: 1.3rem;
    // margin-right: 10px;
    cursor: pointer;
    &:hover {
      color: $theme-btn-hover;
    }
  }
  .datagrid-cancel {
    color: $del-err-red;
  }
}

/* Status classes */
.statusIcon {
  span {
    font-size: 15px;
  }
  .MuiSvgIcon-root,
  span.material-symbols-outlined {
    font-size: 20px;
  }
}
.accepted {
  color: #3c763d;
}
.rejected {
  color: #d9534f;
}
.canceled {
  color: #d9534f;
}
.served {
  color: #214785;
}
.deferred {
  color: $del-err-red;
}
.submitted {
  color: #ff9800;
}
.submitting {
  color: $darker-mid-blue;
}
.submissionFailed {
  color: #d9534f;
}
.underReview {
  color: $mustard;
}

.material-symbols-outlined.no-fill {
  font-family: "Material Symbols Outlined";
  font-variation-settings: "FILL" 0;
}

.simpleDataTable {
  &.no-Header {
    .MuiDataGrid-columnHeaders {
      display: none;
    }
  }
}

.datagrid-top {
  &.d-flex {
    @media only screen and (max-width: 992px) {
      display: block !important;

      .ml-auto {
        text-align: right;
        margin-top: 1.25rem;
      }
    }
  }
}

.activation-url {
  .MuiButtonBase-root {
    min-width: auto;
    width: 35px;
    height: 35px;
    text-align: center;
    margin: 0 auto;
    .MuiButton-startIcon {
      margin-right: 0;
    }
    &.MuiIconButton-root {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.grid-topbar {
  @media only screen and (max-width: 560px) {
    display: block !important;
  }
  .add-data-btn {
    @media only screen and (max-width: 560px) {
      margin-top: 20px;
      text-align: right;
    }
  }
  .material-input {
    @media only screen and (max-width: 560px) {
      width: 100% !important;
    }
  }
}

.filing-history-grid-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media only screen and (max-width: 1080px) {
    display: block;
  }
  .filing-history-right {
    @media only screen and (max-width: 1080px) {
      margin-top: 30px;
      text-align: right;
    }
    .grid-top-icons {
      @media only screen and (max-width: 560px) {
        margin-bottom: 16px;
        display: inline-block;
      }
    }
  }
}

.multiline-grid {
  .MuiDataGrid-columnHeader {
    &:nth-child(2) {
      border-right: 1px solid rgb(224, 224, 224);
      border-left: 1px solid rgb(224, 224, 224);
    }
  }
  .MuiDataGrid-row {
    max-height: 100% !important;
    .MuiDataGrid-cell {
      max-height: 100% !important;
      &:last-child {
        display: none;
      }
      &:nth-child(2) {
        padding-left: 0;
        padding-right: 0;
        border-right: 1px solid rgb(224, 224, 224);
        border-left: 1px solid rgb(224, 224, 224);
      }
      &:nth-child(3) {
        padding-left: 0;
        padding-right: 0;
        // min-width: 100% !important;
      }
      .multiline-grid-column {
        // border-bottom: 1px solid rgb(224, 224, 224);
        padding: 0.3125rem 0.625rem;
        &:last-child {
          border-bottom: 0;
        }
      }
    }
    // &:nth-child(even) {
    //   background-color: $theme-lighter-grey;
    //   &:hover {
    //     background-color: $theme-lighter-grey !important;
    //   }
    // }
    // &:hover {
    //   background-color: initial !important;
    // }
  }
}

.i2file-treeview {
  .treetable-custom-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .treelist-name {
      width: 75%;
      word-break: break-all;
    }
    .MuiTreeItem-label {
      font-size: 0.875rem;
    }
    .MuiSvgIcon-root {
      width: 1.25rem;
      height: 1.25rem;
    }
    .MuiTreeItem-content {
      padding: 0 10px !important;
    }
  }
  .MuiTreeView-root {
    background: $light-grey-bg;
    margin: 2px 0;
    .MuiTreeItem-content {
      padding: 10px;
      background: $light-grey-bg;
    }
    .MuiTreeItem-group {
      background: $theme-white;
      margin-left: 0;
      .MuiTreeItem-content {
        background: $theme-white;
      }
    }
  }
}
