
.firm-account-title{
    svg {
        font-size: 2.5rem;
        vertical-align: text-top;
    }
}

.popup-main{
    position: relative;
    border-radius: 0rem;
    .close-icon{
        position: absolute;
        top: 1.25rem;
        right: 1.875rem;
    }
    .popup-header{
        margin-bottom: 1.5625rem;
    }
    .popup-content-slide{
        h6{
            font-size: 1rem;
            font-weight: 700;
            margin-bottom: .625rem;
        }
        ul{
            li{
                line-height: 1.5rem;
                color: $theme-grey;
                position: relative;
                padding-left: .9375rem;

                &::before{
                    content: "";
                    border-radius: 50%;
                    position: absolute;
                    top: .5625rem;
                    left: 0px;
                    width: .4375rem;
                    height: .4375rem;
                    background-color: $theme-grey;
                }
            }
        }
        h5{
            line-height: 1.125rem;
            line-height: 1.9375rem;
            font-weight: 900;
        }
    }

}


.firm-account{
    .popup-title-icon{
        svg{
            font-size: 2.25rem;
            vertical-align: text-top;
        }
    }
}
.advance-search{
    .MuiDialog-paper{
        width: 50rem;

        h2{
            span{
                width: 80%;
                display: inline-block;
            }
        }
    }

    .radio-label{
        line-height: 42px;
        font-family: "Lato", sans-serif !important;
        @media only screen and (max-width: 480px) {
            width: 100%;
        }
    }
}

.popup-title{
    font-size: 1.5rem !important;
}

// minimum height setc
.history-detail-popup{
    .css-12rl710-MuiPaper-root-MuiDialog-paper{
        min-height: 300px;
    }
   
}
