.sidebar_col {
  position: fixed;
  // width: 18.125rem;
  width: 253px;
  height: 100%;
  top: 0;
  left: 0;
  -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  z-index: 900;
  padding: 0 1.25rem;
  box-sizing: border-box;
  background-color: $lighter-blue;

  @media only screen and (max-width: 1024px) {
    display: none;
  }

  .brand_logo {
    position: relative;
    padding: 17px 0 27px 0;
    &::before {
      position: absolute;
      bottom: 0px;
      left: 0px;
      width: 100%;
      height: 1px;
      background-color: $darker-mid-blue;
      content: "";
    }
    @media only screen and (max-width: 900px) {
      padding: 10px 0 27px 0;
    }
  }

  .brand-logo-mini {
    display: none;
  }

  &.toggle {
    width: 90px;
    padding: 0 14px;

    // @media only screen and (max-width: 1024px) {
    //   display: none;
    // }

    @media only screen and (max-width: 1024px) {
      display: block;
      width: 253px;
    }
    .brand-logo-main {
      display: none;
      @media only screen and (max-width: 1024px) {
        display: block;
      }
      @media only screen and (max-width: 900px) {
        img {
          width: 70%;
        }
      }
    }

    .brand-logo-mini {
      display: block;
      @media only screen and (max-width: 1024px) {
        display: none;
      }
      @media only screen and (max-width: 900px) {
        img {
          width: 60%;
        }
      }
    }

    .MuiCollapse-root {
      display: none;
    }

    .sidebar_col_item {
      .sidebar_col_link {
        display: none;
        @media only screen and (max-width: 1024px) {
          display: block;
        }
      }
      .sidebar_col_arrow {
        display: none;
        @media only screen and (max-width: 1024px) {
          display: block;
        }
      }
    }

    &.panel_hover {
      width: 18.125rem;

      .sidebar_col_item {
        .sidebar_col_link {
          display: block;
        }
        .sidebar_col_arrow {
          display: block;
        }
      }
      .MuiCollapse-root {
        display: block;
      }
    }
  }

  .sidebar_col_list {
    .sidebar_col_item {
      margin-bottom: 0.625rem;
      .sidebar_col_icon {
        svg {
          color: $theme-grey;
        }
      }
      .sidebar_col_link {
        span {
          color: $theme-grey;
          font-weight: 500;
          font-size: 1.125rem;
          line-height: 1.35rem;
          font-family: "Lato", sans-serif;
        }
      }
    }
    .sidebar_col_item_sub {
      padding-left: 52px;
      // overflow: auto;
      // max-height: 330px;

      &.sidebar_col_item_submenu {
        padding-left: 20px;
      }

      .sidebar_col_item {
        margin-bottom: 0px;
        padding: 0 0 0.625rem 0;

        &:hover {
          background: none;
          text-decoration: underline;
        }

        .sidebar_col_link {
          span {
            color: $theme-grey;
            font-weight: 400;
            font-size: 1rem;
            line-height: 1.35rem;
            font-family: "Lato", sans-serif;
          }
        }
      }
    }
  }
}
