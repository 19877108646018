.footer {
  background: $lighter-blue;
  padding: 2rem 0 4rem 0;
  background-image: url("../../images/footer-bg.svg");
  background-position: left bottom;
  background-size: 100% auto;
  background-repeat: no-repeat;

  .footer-text {
    p {
      color: $theme-blue;
      font-size: 1rem;
      font-weight: 500;
      padding-left: 15px;
      border-left: 3px solid $Primary-light;
      margin-bottom: 5px;
      @media only screen and (max-width: 600px) {
        border-left: 0 none;
      }
    }

    .powered-by {
      border-left: none;
      padding-top: 1.5625rem;
      font-size: 0.8125rem;
      font-family: "Lato", sans-serif;
      color: $darker-mid-blue;
    }

    .footer-logo {
      position: absolute;
      bottom: 0px;
      @media only screen and (max-width: 469px) {
        position: relative;
      }
    }
  }

  .footermenu {
    text-align: center;

    p {
      font-size: 1.25rem !important;
      color: $theme-blue !important;
      line-height: 1.8125rem;
      font-family: "Hanuman", serif;
      text-transform: uppercase;
    }

    ul {
      li {
        font-size: 1.25rem;
        line-height: 1.875rem;

        a {
          color: $theme-blue;
          text-decoration: underline;
          cursor: pointer;
        }

        &:hover {
          a {
            text-decoration: none;
          }
        }
      }
    }
  }

  .contact-dtl {
    p {
      font-size: 1.25rem !important;
      color: $theme-blue !important;
      line-height: 1.8125rem;
      font-family: "Hanuman", serif;
      text-transform: uppercase;
    }
    ul {
      li {
        font-size: 1.25rem;
        line-height: 1.875rem;
        color: $theme-blue;
        font-family: "Hanuman", serif;
        font-weight: bold;
        svg {
          opacity: 0.5;
        }
        &:last-child {
          font-size: 1.0625rem;
          line-height: 20px;
        }
      }
      &.contact-left-border {
        border-left: 5px solid $blue-light;
        @media only screen and (max-width: 469px) {
          border-left: 0;
        }
      }
    }
  }
}

.dash-footer {
  padding: 0 3.125rem;
  color: $mid-dark-grey;
  a {
    padding-left: 1.25rem;
    color: $Primary-light;
    text-decoration: underline;
  }
}
